import { createBrowserHistory } from "history";

const history = createBrowserHistory();
history.listen(location => {
  console.debug("Tracking page view: + " + location.pathname);
  if (ga !== undefined && ga !== null) {
    ga("send", "pageview", location.pathname);
  } else {
    console.error("GA not defined");
  }
});
export default history;
